@media print {
  .mat-sidenav-container {
    overflow: visible !important;
  }
  .mat-sidenav, .mat-drawer, .page-footer, .mobile-menu-toggle {
    display: none !important;
  }
  .mat-sidenav-content, .mat-drawer-content {
    margin: 0 !important;
  }
}
