@use '@angular/material' as mat;
@import '@angular/material/theming';

$app-theme-primary: mat.define-palette(mat.$green-palette, 600);
$app-theme-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
$app-theme-warn:    mat.define-palette(mat.$red-palette);

$app-typography: mat.define-typography-config();

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-theme-primary,
    accent: $app-theme-accent,
    warn: $app-theme-warn
  ),
  typography: $app-typography,
  density: 0,
));

$icomoon-font-path: "fonts";

$care-theme: (
  progress: mat.get-color-from-palette(mat.$teal-palette, 500),
  hospital: mat.get-color-from-palette(mat.$red-palette, A200),
  meal: mat.get-color-from-palette(mat.$light-green-palette, 600),
  vital: mat.get-color-from-palette(mat.$purple-palette, 500),
  excretion: mat.get-color-from-palette(mat.$orange-palette, 700),
  excretion-alt: mat.get-color-from-palette(mat.$light-blue-palette, 700),
  excretion-alt2: mat.get-color-from-palette(mat.$grey-palette, 500),
  bathing: mat.get-color-from-palette(mat.$cyan-palette, 500),
  transport: mat.get-color-from-palette(mat.$indigo-palette, 500),
  report: mat.get-color-from-palette(mat.$deep-orange-palette, 700),
  note: mat.get-color-from-palette(mat.$brown-palette, 400),
  assessment: mat.get-color-from-palette(mat.$lime-palette, 700),
  plan: mat.get-color-from-palette(mat.$amber-palette, 700),
  planMonitoring: mat.get-color-from-palette(mat.$pink-palette, 700),
  planReview: mat.get-color-from-palette(mat.$indigo-palette, 400)
);

$report-theme: (
  accident: mat.get-color-from-palette(mat.$red-palette, 900),
  complaint: mat.get-color-from-palette(mat.$purple-palette, 700),
  nearmiss: mat.get-color-from-palette(mat.$blue-palette, 700)
);
