@use '@angular/material' as mat;
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  position: fixed;
  width: 100%;
}

router-outlet + * {
  flex: 1;
  display: flex;
  flex-direction: column;
}

router-outlet + *, .mat-drawer-content {
  > .page-header, > .page-footer {
    flex-shrink: 0;
  }

  > .page-content {
    flex: 1;
  }
}

.mat-sidenav-container {
  .mat-drawer-inner-container, .mat-drawer-content {
    display: flex;
    flex-direction: column;
  }
}

.page-header {
  position: relative;
  z-index: 150;

  &:not(.default) {
    background-color: mat.get-color-from-palette($app-theme-primary);
    color: mat.get-contrast-color-from-palette($app-theme-primary, 600);

    .toolbar-icon {
      background-color: mat.get-color-from-palette($app-theme-primary);
      color: mat.get-contrast-color-from-palette($app-theme-primary, 600);
    }
  }

  .toolbar-icon {
    height: 100%;
    margin: 0 0 0 -16px;
    padding: 0;
    width: 64px;
    min-width: 64px;
    border-radius: 0;

    @media (max-width: 599px) {
      width: 56px;
      min-width: 56px;
      margin-left: 0;
    }

    .mat-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      width: 64px;
      height: 64px;
      margin-right: 0;
      line-height: 1;

      @media (max-width: 599px) {
        width: 56px;
        height: 56px;
      }

      &.chevron {
        position: absolute;
        left: 0;
        width: auto;
        font-size: 24px;
      }
    }
  }
}

.toolbar-title {
  flex: 1 0;
}

.page-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $black-12-opacity;
  padding: 12px 16px;
  background-color: white;
}

.page-footer-left > * {
  margin-right: 8px !important;
}

.page-footer-right > * {
  margin-left: 8px !important;
}

.filter-container {
  padding: 10px 16px;
  height: 24px;
  background-color: #eee;
}

.side-panel {
  width: 360px;
  height: 100%;
}

.color-warn {
  color: mat.get-color-from-palette($app-theme-warn);
}

.color-muted {
  color: rgba(0, 0, 0, 0.43);
}

.edit-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  vertical-align: super;
  margin: 0 4px;

  &.dirty {
    background-color: mat.get-color-from-palette(mat.$blue-palette, 500);
  }

  &.invalid {
    background-color: mat.get-color-from-palette($app-theme-warn);
  }
}


a {
  text-decoration: none;
  color: mat.get-color-from-palette($app-theme-primary);
  cursor: pointer;

  &:active {
    color: mat.get-color-from-palette($app-theme-accent);
  }

  &:hover {
    color: mat.get-color-from-palette($app-theme-primary, darker);
  }
}

th {
  text-align: left;
}

hr.spacer {
  border-color: transparent;
  margin: 8px 0;
}

.kana {
  font-size: 0.6em;
  line-height: 1;
}

.section-header {
  border-bottom: 1px solid $black-12-opacity;

  .mat-mdc-slide-toggle {
    font-size: 14px;
  }
}

.section-content:not(:last-child) {
  margin-bottom: 2rem;
}

.section-divider {
  margin-bottom: 1rem !important;
}

.mat-mdc-card-avatar {
  &.male {
    background-color: mat.get-color-from-palette(mat.$indigo-palette, 500);
  }

  &.female {
    background-color: mat.get-color-from-palette(mat.$pink-palette, 400);
  }

  &.text-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #999;
    color: white;
    text-align: center;
    margin-bottom: 16px;

    &.larger {
      width: 56px;
      height: 56px;
    }

    &.plain {
      background-color: transparent;
      color: unset;
    }
  }
}

.mat-mdc-checkbox.select-check {
  .mdc-checkbox {
    width: 32px;
    height: 32px;
    flex-basis: 32px;

    .mdc-checkbox__native-control {
      width: 54px;
      height: 54px;
    }
  }

  .mdc-checkbox__background {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  .mdc-checkbox, .mdc-checkbox:hover, .mdc-checkbox:not(:disabled):active {
    .mdc-checkbox__native-control {
      &:enabled, &[disabled] {
        &:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
          background-color: white;
        }
      }
    }
  }
}

.postal-input {
  width: 220px;

  // .mat-icon {
  //   font-size: 12px;
  // }

  .mat-mdc-button {
    font-size: 12px;
    line-height: 30px;
    padding: 0 8px;
  }
}

.mdc-card.meta-card {
  background-color: $black-6-opacity;
}

.meta-table {
  @include mat.typography-level($app-typography, body-2);

  th {
    width: 30%;
  }

  th, td {
    height: 32px;
  }

  @media (max-width: 599px) {
    display: block;

    tbody, tr, th, td {
      display: block;
      width: 100%;
      height: auto;
    }

    th {
      border-bottom: 1px solid $black-6-opacity;
      margin-bottom: 4px;
    }

    tr:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.error-messages {
  padding: 16px;
  background-color: map-get($app-theme-warn, default);
  color: map-get($app-theme-warn, default-contrast);
  border-radius: 2px;
}


.search-area {
  position: absolute;
  z-index: 120;
  top: 62px;
  width: 100%;

  .mat-mdc-card {
    width: calc(100% - 32px);
    max-width: 768px;
    margin: 0 auto;
  }
}

.form-control-row {
  margin: {
    left: -8px;
    right: -8px;
  }
}

.form-control {
  padding: {
    left: 8px;
    right: 8px;
  }
}

.input-group {
  &.checkbox {
    margin: 1.5em 0;
  }

  > label {
    display: inline-block;
    width: 120px;
  }
}

.transparent-input {
  box-sizing: border-box;
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  padding: 0;
  height: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font: inherit;
  letter-spacing: inherit;

  &:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.checkbox-cell {
  width: 16px;
}

.button-toggle-with-placeholder {
  position: relative;
  margin: 1em 0;

  .placeholder {
    color: rgba(black, 0.38);
    position: absolute;
    padding: 0 2px;
    top: -22px;
    left: -2px;
    transform: scale(0.75);
    transform-origin: left top;
  }
}

.sortable-list {
  display: flex;

  .sortable-list-content {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    a {
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }
  }
}

.mat-mdc-list-base.free-height {
  &.mdc-list-item--with-two-lines {
    height: auto;
  }

  .mat-mdc-list-item {
    .mat-mdc-list-item-title {
      font-weight: 600;

      small {
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.46);
      }
    }

    .mat-mdc-list-item-line {
      white-space: pre-line;
    }
  }
}

.mat-mdc-list-item {
  &.list-placeholder {
    color: rgba(black, 0.38);
  }
  &.selected {
    background-color: mat.get-color-from-palette($app-theme-primary, lighter, 0.4);
  }
  &.split-button {
    box-sizing: border-box;
    padding-right: 48px !important;

    .mat-mdc-list-item-meta {
      width: 48px;
      position: absolute;
      right: -16px;
      border-radius: 0;
      height: 100%;

      > .mat-mdc-button-base {
        height: 100%;
        border-radius: 0;
      }

      .mat-mdc-button-persistent-ripple {
        border-radius: 0;
      }
    }
  }
}

.mat-mdc-card > .mat-toolbar:first-child {
  margin: -16px -16px 16px;
  width: auto;
  min-height: 48px;
  font-size: 16px;
  background-color: mat.get-color-from-palette($app-theme-primary, lighter, 0.3);
  color: mat.get-color-from-palette($app-theme-primary, lighter-contrast);
  // border-bottom: 1px solid mat-color($app-theme-primary, default);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  .mat-toolbar-row {
    height: 48px;
  }
}

.mat-expansion-panel-body {
  .mat-mdc-list-base {
    padding-top: 0;
    margin: 0 -24px -16px;

    + .mat-mdc-paginator {
      margin: 16px -24px -16px;
    }
  }
}

.progress-spinner-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip-icon {
  line-height: 1 !important;
}

.care-buttons {
  opacity: 0.4;
  transition: opacity 0.2s;

  .datatable-body-row:hover & {
    opacity: 1;
  }
}

.care-button, .mat-icon {
  @each $type, $color in $care-theme {
    &.#{$type} {
      color: $color;
    }
  }
}

.mat-mdc-standard-chip.mat-mdc-chip-selected {
  @each $type, $color in $care-theme {
    &.#{$type} {
      background-color: $color !important;
    }
  }
  @each $type, $color in $report-theme {
    &.#{$type} {
      background-color: $color !important;
    }
  }
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.nolabel {
  .mdc-evolution-chip__action--primary {
    padding-right: 0;
  }

  .mdc-evolution-chip__graphic {
    padding-right: 4px;
  }
}

.type-badge {
  display: inline-flex;
  align-items: center;

  .mat-icon {
    margin-right: 8px;
  }
}

.rounded-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: white;
  background-color: #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.medium {
    width: 36px;
    height: 36px;

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
}

.time-table {
  flex: 1;
  border-top: 1px solid $black-12-opacity;

  * {
    box-sizing: border-box;
  }

  .column-header, .cell, .resource-column-header, .resource-cell {
    border: 0 solid $black-12-opacity;
    border-width: 0 1px 1px 0;
  }

  .column {
    background-color: white;

    &.current {
      background-color: mix(white, yellow, 80%);
    }

    &.future {
      background-color: mix(white, black, 80%);
    }

    &.saturday .column-header {
      color: blue;
    }

    &.sunday .column-header {
      color: red;
    }
  }

  .column-header {
    width: 64px;
    height: 28px;
    position: sticky;
    top: 0;
    z-index: 150;
    background-color: white;
    text-align: center;
    line-height: 28px;
  }

  .cell {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.absent-1 {
      background-color: mix(white, black, 92%);
    }
    &.absent-2 {
      background-color: mix(white, red, 92%);
    }
    &.unoccupied {
      background-color: mix(white, black, 80%);
    }
  }

  .count-column {
    position: sticky;
    left: 290px;
    z-index: 200;

    .column-header, .cell {
      width: 48px;
    }
  }

  .extra-column {
    position: sticky;
    z-index: 210;
  }

  .resource-column {
    position: sticky;
    left: 0;
    z-index: 200;
  }

  .resource-column-header {
    height: 28px;
    position: sticky;
    top: 0;
    z-index: 250;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  .resource-cell {
    width: 290px;
    height: 64px;
    padding-right: 8px;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .resource-cell-label {
    line-height: inherit;
    text-align: left;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0 16px;
    justify-content: start;
    letter-spacing: 0;
  }

  .resource-cell-button {
    width: 40px;
    height: 100%;
    border-radius: 0;
    padding: 21px 8px 18px;
    line-height: 1;

    .mat-mdc-button-persistent-ripple {
      border-radius: 0;
    }
  }
}

.grid-dl {
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
  @include mat.typography-level($app-typography, body-2);

  dt {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.46);
  }

  dd {
    margin: 0;

    .mat-icon {
      height: 0;
      vertical-align: middle;
      margin-left: 8px;
      display: inline-flex;
      align-items: center;
    }
  }
}

.mat-badge.badge-closer {
  &.mat-badge-before .mat-badge-content {
    left: -4px;
  }

  &.mat-badge-above .mat-badge-content {
    top: -4px;
  }
}

.prev-next-resident {
  background-color: white;
  border-bottom: 1px solid $black-12-opacity;

  .prev-resident-button, .next-resident-button {
    line-height: inherit;
    text-align: left;
    padding: 8px 4px;
    border-radius: 0;
    height: auto;

    cm-resident-name-card {
      display: flex;
    }
  }

  .prev-resident-button {
    padding-right: 16px;
  }

  .next-resident-button {
    padding-left: 16px;
    flex-direction: row-reverse;

    .mat-icon {
      margin-left: 8px;
      margin-right: 0;
    }
  }
}

.dashboard-timeline .timeline-note-search {
  padding: 0 16px;
}

a.disabled {
  color: unset;
  cursor: default;
}
