/* mat-select overrides */

.mat-mdc-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-mdc-checkbox {
  width: auto;
  height: auto;
  font-size: inherit;
}

.mat-mdc-card-actions {
  display: flex !important;
  justify-content: flex-end;
}

.mat-mdc-tab-group {
  &.full-screen {
    flex: 1;
    .mat-mdc-tab-body-wrapper {
      flex: 1;
    }
  }

  &.filled {
    flex: 1;
    margin: 0 -24px 0;
    overflow: hidden;
    max-width: none;

    .mat-mdc-tab-body-wrapper {
      flex: 1;
    }
    .mat-mdc-tab-body-content {
      padding: 0 24px;
    }
  }

}

.mat-mdc-tab-group.scroller-inside {
  .mat-mdc-tab-body-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.mat-mdc-dialog-actions, .mat-mdc-card-actions {
  .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
    margin: 0 4px;
    flex: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// .mat-form-field {
  .mat-mdc-radio-button, .mat-mdc-checkbox {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    .mdc-form-field {
      height: 24px;
    }
  }
// }

button.mat-mdc-list-item {
  text-align: left;
}

.mat-icon.mat-icon-inline {
  overflow: visible;
}

.cdk-drag-handle, .cdk-drag-preview {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
}

.mat-mdc-tooltip {
  white-space: pre-wrap;
}

table.mat-mdc-table {
  td.mat-mdc-cell, th.mat-mdc-header-cell {
    padding: 8px;

    &:first-of-type {
      padding-left: 24px;
    }

    &:last-of-type {
      padding-right: 24px;
    }
  }

  tr.mat-mdc-row {
    height: auto;
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 16px !important;
}
.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  margin-left: 16px !important;
}

.mat-mdc-list-base[dense] {
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
    height: 60px;

    .mdc-list-item__primary-text::before {
      height: 24px;
    }
  }
}
