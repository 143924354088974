/* You can add global styles to this file, and also import other style files */

@import 'styles/variables';

@import url('https://fonts.googleapis.com/css?family=Oswald');

@tailwind components;
@tailwind utilities;

@import 'styles/material';
@import 'styles/icomoon';
@import 'styles/global';
@import 'styles/overrides';
@import 'styles/print';
